// page_block_form_type

export const main_consultation_ContactUs_static = [
  {
    type: "text",
    name: "name",
    id: "main_consultation_ContactUs_static-name",
    text: "Your full name",
    required: true,
    autocomplete: "name"
  },

  {
    type: "tel",
    name: "phone",
    id: "main_consultation_ContactUs_static-phone",
    text: "Phone number",
    required: true,
    autocomplete: "tel"
  },

  {
    type: "email",
    name: "email",
    id: "main_consultation_ContactUs_static-email",
    text: "Email",
    required: true,
    autocomplete: "email"
  },
]

export const ContactForm_modal = [
  {
    type: "name",
    name: "name",
    id: "ContactForm_modal-name",
    text: "Your full name",
    required: true,
    autocomplete: "name"
  },

  {
    type: "tel",
    name: "phone",
    id: "ContactForm_modal-phone",
    text: "Phone number",
    required: true,
    autocomplete: "tel"
  },

  {
    type: "email",
    name: "email",
    id: "ContactForm_modal-email",
    text: "Email",
    required: true,
    autocomplete: "email"
  },
]
