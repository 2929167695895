import expertiseImg from '../assets/images/benefits/Expertise.png';
import strategyImg from '../assets/images/benefits/Strategy.png';
import clarityImg from '../assets/images/benefits/Clarity.png';
import attentionImg from '../assets/images/benefits/Attention.png';
import careImg from '../assets/images/benefits/Care.png';
import supportImg from '../assets/images/benefits/Support.png';

export const benefits = [
  {
    id: 1,
    title: 'Expertise',
    desc: 'Providing detailed legal advice for individual requests',
    img: expertiseImg
  },
  {
    id: 2,
    title: 'Strategy',
    desc: 'Planning the stages of starting a company, developing a strategy and creating a business model. Assessing possible risks',
    img: strategyImg
  },
  {
    id: 3,
    title: 'Clarity',
    desc: 'Calculating costs based on the goals and objectives of a business',
    img: clarityImg
  },
  {
    id: 4,
    title: 'Attention',
    desc: 'Quick response in case of any additional administrative requirements or execution of necessary documents',
    img: attentionImg
  },
  {
    id: 5,
    title: 'Care',
    desc: "We provide support at all stages of the company's opening process",
    img: careImg
  },
  {
    id: 6,
    title: 'Support',
    desc: 'Support after obtaining a license',
    img: supportImg
  },
]