export const mainFAQ = [
  {
    title: 'What type of commercial organization can be registered in the United Arab Emirates?',
    body: "The Emirates has all traditional types of legal entities: joint stock companies, public limited companies, limited liability companies, as well as representative offices, branches and sole proprietorships."
  },
  {
    title: 'Do I need to be physically present when registering a business?',
    body: "The preparation and submission of documents takes place on our side, so your presence is not required during work. You can track the status of the order in your personal account. But at the time of obtaining a visa, you must be in the UAE to undergo a medical examination and take fingerprints."
  },
  {
    title: 'How much does it cost to open a company in Dubai?',
    body: "The total cost depends on a range of factors: type of legal entity, license price, incorporation zone. To find out the exact amount, fill in the feedback form and we will call you back as soon as possible."
  },
  {
    title: 'What tax payments are imposed on the business?',
    body: "If a company operates in mainland, then if the net profit is more than 102,000 USD, you will have to pay 9% corporate tax. Additionally, when a revenue crosses a certain threshold you will have to pay 5% VAT."
  },
  {
    title: 'Which free zones should I pay attention to in Dubai?',
    body: "It depends on what tasks your business will solve. That is why we will be able to suggest the right free-zone for you after consultation."
  },
]