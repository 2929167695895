export const stateAnchors = [
  {
    id: 1,
    slug: 'structure',
    title: {
      ru: 'Государственное и политическое устройство ОАЭ',
      en: 'The state and political structure of the UAE'
    }
  },
  {
    id: 2,
    slug: 'contacts',
    title: {
      ru: 'Контакты госучреждений ОАЭ',
      en: 'Contacts of UAE government agencies'
    }
  },
  {
    id: 5,
    slug: 'emirates',
    title: {
      ru: 'Эмираты',
      en: 'Emirates'
    }
  },
  {
    id: 3,
    slug: 'free-zones',
    title: {
      ru: 'Свободные экономические зоны в ОАЭ',
      en: 'Free economic zones in the UAE'
    }
  },
  // {
  //   id: 4,
  //   slug: 'free-zones-advantages',
  //   title: 'Преимущества свободных зон'
  // },
]