export const consultationSteps = [
  {
    title: "Request",
    body:
      <div>
        <p className="f-body-2">
          You can leave a request, and we will contact you within 15 minutes to discuss your task and answer any questions you may have.
        </p>
      </div>
  },
  {
    title: "Consultation",
    body:
      <div>
        <ul>
          <li className="f-body-2">
            We are discussing your request to open a business
          </li>
          <li className="f-body-2">
            We create a step-by-step strategy for launching and developing a business
          </li>
          <li className="f-body-2">
            We will consult with our international legal adviser, who will dive into your tasks and answer all your questions, taking into account all the subtleties of the legislation of the Emirates
          </li>
        </ul>
      </div>
  },
  {
    title: "Signing the contract",
    body:
      <div>
        <p className="f-body-2">
          We will work with you to negotiate the terms of the contract, taking into account all of your requests.
        </p>
      </div>
  },
  {
    title: "The beginning of the company's opening",
    body:
      <div>
        <p className="f-body-2-bold">
          Opening a local company includes:
        </p>

        <ul>
          <li className="f-body-2">
            Selection of jurisdiction
          </li>
          <li className="f-body-2">
            Approval of the company's name in government agencies
          </li>
          <li className="f-body-2">
            Coordination of the company's activity
          </li>
          <li className="f-body-2">
            Receipt of the institution's card
          </li>
          <li className="f-body-2">
            Registration in emigration
          </li>
          <li className="f-body-2">
            Making a deposit
          </li>
          <li className="f-body-2">
            Receipt of the memorandum
          </li>
          <li className="f-body-2">
            Legal address
          </li>
          <li className="f-body-2">
            Production of the company's seal
          </li>
          <li className="f-body-2">
            Obtaining an immigration card
          </li>
          <li className="f-body-2">
            Translation services in government agencies and banks
          </li>
          <li className="f-body-2">
            Personal support: individual transfer, comfortable travel conditions in the UAE with your personal manager
          </li>
        </ul>

        <p className="f-body-2-bold">
          The opening of a company in free zone includes:
        </p>

        <ul>
          <li className="f-body-2">
            Selection of the free zone
          </li>
          <li className="f-body-2">
            Approval of the company's name in government agencies
          </li>
          <li className="f-body-2">
            Coordination of the company's activity
          </li>
          <li className="f-body-2">
            Receipt of the institution's card
          </li>
          <li className="f-body-2">
            Registration in emigration
          </li>
          <li className="f-body-2">
            Legal address
          </li>
          <li className="f-body-2">
            Production of the company's seal
          </li>
          <li className="f-body-2">
            Obtaining an immigration card
          </li>
          <li className="f-body-2">
            Translation services in government agencies and banks
          </li>
          <li className="f-body-2">
            Personal support: individual transfer, comfortable travel conditions in the UAE with your personal manager
          </li>
        </ul>
      </div>
  },
  {
    title: "Opening an account",
    body:
      <div>
        <ul>
          <li className="f-body-2">
            Selection of banks for opening an account of the Customer's company
          </li>
          <li className="f-body-2">
            Advising on the preparation of a package of documents for opening a bank account
          </li>
          <li className="f-body-2">
            Preliminary submission of documents in electronic form to the selected bank
          </li>
          <li className="f-body-2">
            Consultations with representatives of UAE banks on approval of documents for opening a bank account
          </li>
          <li className="f-body-2">
            Coordination of the account opening procedure with the management of the bank branch
          </li>
        </ul>
      </div>
  },
  {
    title: "Company readiness",
    body:
      <div>
        <p className="f-body-2">
          Transfer of a package of documents to the client
        </p>
      </div>
  },
  {
    title: "Business support",
    body:
      <div>
        <p className="f-body-2-bold">
          Legal and financial consulting services:
        </p>

        <ul>
          <li className="f-body-2">
            Corporate and tax law
          </li>
          <li className="f-body-2">
            Registration of legal entities
          </li>
          <li className="f-body-2">
            Reorganization and liquidation of organizations
          </li>
          <li className="f-body-2">
            Representation in courts and arbitration
          </li>
          <li className="f-body-2">
            Registration of the issue of securities
          </li>
          <li className="f-body-2">
            Legalization of documents
          </li>
          <li className="f-body-2">
            Labor law and personnel audit
          </li>
        </ul>

        <p className="f-body-2-bold">
          Obtaining a residence permit and settling in
        </p>

        <ul>
          <li className="f-body-2">
          Obtaining a residence permit, Emirates ID, Ejari & Dewa
          </li>
          <li className="f-body-2">
          Obtaining a work visa
          </li>
          <li className="f-body-2">
          Driver's license, medical insurance
          </li>
          <li className="f-body-2">
          Assistance in getting into school, kindergarten
          </li>
          <li className="f-body-2">
          Registration of yachts, ships, cars
          </li>
        </ul>
      </div>
  },
]